import clsx from 'clsx';
import { SVGProps, VFC } from 'react';
import sprite from '~/icons/sprite.svg';

interface IconProps extends SVGProps<SVGElement> {
  id: string;
  ariaHidden?: boolean;
  size?: string;
  className?: string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const sizes: { [key: string]: any } = {
  custom: '', // use className to add custom size classes
  full: 'h-full w-full',
  xs: 'h-3 w-3',
  sm: 'h-4 w-4',
  md: 'h-5 w-5',
  lg: 'h-6 w-6',
  xl: 'h-7 w-7',
};

const Icon: VFC<IconProps> = ({
  id,
  ariaHidden = true,
  size = 'md',
  className,
  ref,
  ...props
}) => (
  <svg
    {...props}
    aria-hidden={ariaHidden}
    className={clsx('shrink-0', sizes[size], className)}
  >
    <use href={`${sprite}#${id}`} />
  </svg>
);

export default Icon;
